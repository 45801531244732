import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';

const autoComplete = require('@tarekraafat/autocomplete.js/dist/js/autoComplete');

export const initAutocomplete = () => {
    // VARS
    const autocompleteForm = document.querySelector('[data-autocomplete-form]');
    if (!autocompleteForm) return;
    const input = document.querySelector('[data-autocomplete-input]');
    const checkNewButton = document.querySelector('[data-autocomplete-result-check-new]');
    const compatibilityResult = document.querySelector('[data-autocomplete-result]');
    const findeResultMessage = document.querySelector('[data-autocomplete-result-finded]');
    const noResultMessage = document.querySelector('[data-autocomplete-result-no-result]');

    // autoComplete

    const autoCompleteSearch = new autoComplete({
        data: {
            src: async () => {
                const source = await fetch('/compatibility.json');
                const data = await source.json();
                return data;
            },
            key: ['searchString'],
            cache: true,
        },
        sort: (a, b) => {
            if (a.match < b.match) return -1;
            if (a.match > b.match) return 1;
            return 0;
        },
        selector: '[data-autocomplete-input]',
        threshold: 0,
        debounce: 0,
        searchEngine: 'loose',
        highlight: true,
        maxResults: 20,
        resultsList: {
            render: true,
            container: (source) => {
                source.setAttribute('id', 'autoComplete-list');
            },
            destination: '[data-autocomplete-input]',
            position: 'afterend',
            element: 'ul',
        },
        resultItem: {
            content: (data, source) => {
                source.innerHTML = data.match;
                document.querySelector('[data-autocomplete-result-no-result]').classList.add('hidden');
            },
            element: 'li',
        },
        noResults: () => {
            compatibilityCheck(null);
        },
        onSelection: (feedback) => {
            compatibilityCheck(feedback);
        },
    });

    function compatibilityCheck(feedback) {
        if (Boolean(feedback)) {
            const selection = feedback.selection.value.searchString;
            input.value = '';

            // Change placeholder with the selected value
            input.setAttribute('placeholder', selection);

            autocompleteForm.addEventListener('submit', (event) => {
                event.preventDefault();
                findeResultMessage.classList.remove('hidden');
                noResultMessage.classList.add('hidden');
                compatibilityResultRender();
                input.setAttribute('placeholder', `${input.dataset.placeholder}`);
            });
        } else if (!Boolean(feedback)) {
            autocompleteForm.addEventListener('submit', (event) => {
                event.preventDefault();
                findeResultMessage.classList.add('hidden');
                noResultMessage.classList.remove('hidden');
                input.setAttribute('placeholder', `${input.dataset.placeholder}`);
                compatibilityResultRender();
            });
        }
    }

    function compatibilityResultRender() {
        compatibilityResult.style.display = 'flex';
    }

    checkNewButton.addEventListener('click', function (event) {
        event.preventDefault();
        compatibilityResult.style.display = 'none';
    });

    autocompleteForm.addEventListener('submit', (event) => {
        autoCompleteSearch.onSelection();
        event.preventDefault();
    });
};
