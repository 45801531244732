import { initAccordion } from './components/initAccordion';
import { InitMobileMenu } from './components/initMobileMenu';
import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initTabs } from './components/initTabs';
import { initAutocomplete } from './components/initAutoComplate';
import { initAutocompleteDevices } from './components/forms/initAutoCompleteDevice';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initSliders } from './components/initSliders';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';
import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initJivosite } from './components/initJivosite';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initPageLoader();
    initChunkPageBeforeCheckout();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initLazyLoadFonts();
    const mobileMenu = new InitMobileMenu();
    initScrollLocker();
    initAccordion();
    initForms();
    initStickyTableHeader();
    initScrollToAnchor();
    initTabs();
    initAutocomplete();
    initAutocompleteDevices();
    initMobileFeatureMenu(mobileMenu);
    initRenderPricesPage();
    initLangSwitcher();
    initRippleAnimation();
    initEmailAutocompleteDropdown();
    initSliders();
    initMixpannel();
    initJivosite();
}
